import { createApp } from "vue";

/* import acios */
import axios from 'axios';
import VueAxios from 'vue-axios';

/* import emitter bus */
import mitt from 'mitt';

import UserManagementTemplate from '../../vue/templates/userManagement.vue';

export default class UserManagement {
    constructor() {
        const emitter = mitt();

        const app = createApp(UserManagementTemplate);

        app.config.globalProperties.emitter = emitter;

        app.use(VueAxios, axios);
        app.mount('#user-management-component');
    }
}