<template>
    <div class="blog-excerpt">
        <div class="mb-4">
            <div class="row">
                <div class="col-12">
                    <div :class="alertClass" role="alert" :data-id="id">
                        <div class="me-auto">
                            Weekly Blog Post
                        </div>
                        <div>
                            <button v-on:click="deleteBlog()" class="btn btn-light btn-sm mr-1"><font-awesome-icon
                                    icon="fa-solid fa-trash-alt" /> Clear</button>
                            <button v-on:click="saveBlog()" class="btn btn-primary btn-sm"><font-awesome-icon
                                    icon="fa-solid fa-floppy-disk" /> Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h6>Longer Blog Excerpt</h6>
                    <small>For Facebook and LinkedIn</small>
                    <div class="mb-1 mt-2">
                        <label>Post</label>
                        <textarea v-model="blogExcerpt" class="form-control" placeholder="Enter your Longer Post Content here"
                            rows="5"></textarea>
                        <span class="text-danger u-hidden validate-post">Please enter your blog excerpt</span>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <h6>Shorter Blog Excerpt</h6>
                    <small>For X Twitter</small>
                    <div class="mb-1 mt-2">
                        <label>Post</label>
                        <textarea :id="textAreaId" class="form-control" v-model="shortBlogExcerpt"
                            placeholder="Enter your Shorter Post Content here" rows="5"></textarea>
                        <span class="text-danger u-hidden validate-twitter">Please enter your shorter blog excerpt</span>
                    </div>
                    <div class="mb-3">
                        <span class="character-count">{{ showCharacterCount }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="mb-2">
                        <label>Hashtags</label>
                        <input v-model="hashtagData" type="text" class="form-control"
                            placeholder="Enter each hashtag followed by a space" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import { createToast } from 'mosha-vue-toastify';

export default {
    name: 'blog-excerpt-component',
     props: [
        "id",
        "language",
        "excerpt",
        "shortExcerpt",
        "hashtags",
        "edited",
        "weekNumber",
        "year"
    ],
    data() {
        return {
            blogExcerpt: "",
            shortBlogExcerpt: "",
            hashtagData: "",
            characterCount: 0,
            showCharacterCount: "Total Characters: 0",
            mutatedEdited: false,
            firstLoad: true,
            hashtagLength: 0,
        }
    },
    mounted() {
        // this.emitter.on("change-language", () => {
        //     this.blogExcerpt = '';
        //     this.shortBlogExcerpt = '';
        //     this.hashtagData = '';
        //     this.mutatedEdited = false;            
        // });
    },
    created() {
        this.blogExcerpt = this.excerpt ? this.excerpt : "";
        this.shortBlogExcerpt = this.shortExcerpt ? this.shortExcerpt : "";
        this.hashtagData = this.hashtags ? this.hashtags : "";
        this.mutatedEdited = this.edited ? this.edited : false;
    },
    computed: {
        alertClass: function () {
            switch (this.mutatedEdited) {
                case true:
                    return "alert alert-small alert-success d-flex";
                case false:
                    return "alert alert-small alert-warning d-flex";
            }
        },
        editorId: function () {
            return "#" + this.id;
        },
        textAreaId: function () {
            return '#shortPost-' + this.id;
        },
    },
    watch: {
        shortBlogExcerpt() {
            this.characterCount = 0; 

            if (this.shortBlogExcerpt === null || this.shortBlogExcerpt === "")
            {
                return;
            }

            if (this.hashTagData === null) {
                this.characterCount = this.shortBlogExcerpt.length;
                return;
            }

            this.characterCount = this.shortBlogExcerpt.length + this.hashtagData.length;
        },
        hashtagData() {

            if (this.hashtagData == null) {
                return;
            }

            this.characterCount = this.characterCount + (this.hashtagData.length - this.hashtagLength);
            this.hashtagLength = this.hashtagData.length;
        },
        characterCount() {
            const characterSpan = document.querySelector('.character-count');
            const textArea = document.getElementById(this.textAreaId);

            if (this.characterCount > 280) {
                characterSpan.classList.add("text-danger");
                characterSpan.classList.add("font-weight-bold");
                this.showCharacterCount = `Total Characters: ${this.characterCount} (exceeds the limit of 280 characters)`;
                textArea.classList.add('overlimit');
            }
            else {
                if (characterSpan.classList.contains("text-danger")) {
                    characterSpan.classList.remove("text-danger");
                    characterSpan.classList.remove("font-weight-bold");
                }
                textArea.classList.remove('overlimit');
                this.showCharacterCount = `Total Characters: ${this.characterCount}`;
            }
        }
    },
    methods: {
        deleteBlog() {
            const apiEndpoint = `/api/blog/deleteexcerpt/?id=${this.id}`;

            axios({
                url: apiEndpoint,
                method: "POST"
            }).then(response => {
                console.log(response);
                this.emitter.emit("clear-post");
                createToast('Blog cleared', { position: 'bottom-right', type: 'success', showIcon: true });
            });
        },
        saveBlog() {

            const blogExcerpt = {
                'id': this.id,
                'weekNumber': this.weekNumber,
                'year': this.year,
                'excerpt': this.blogExcerpt,
                'shortExcerpt': this.shortBlogExcerpt,
                'hashtags': this.hashtagData,
                'Language': this.language
            };

            const apiEndpoint = '/api/blog/saveexcerpt/';

            axios({
                url: apiEndpoint,
                method: "POST",
                data: blogExcerpt
            }).then(response => {
                this.emitter.emit("blog-id", response.data);
                this.mutatedEdited = true;
                createToast('Blog saved', { position: 'bottom-right', type: 'success', showIcon: true });
            });
        },
    },

}
</script>