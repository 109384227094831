<template>
    <div class="modal fade" id="confirmClear" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Clear</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure? This will permanently erase your data.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" v-on:click="confirmClear()">Clear</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'confirm-clear-component',
    methods: {
        confirmClear() {
            this.emitter.emit('confirm-clear');
            $('#confirmClear').modal('hide');
        }
    },
}
</script>